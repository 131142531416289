import React from "react"
import "./sidebar.css"
import { getSrc } from "gatsby-plugin-image"

export type SideBarDataProps = {
  recentPosts: any
}

export const SideBar: React.FC<SideBarDataProps> = ({ recentPosts }) => {
  return (
    <div className="col-lg-4">
      <div className="sidebar">
        <div className="sidebar-item recent-posts">
          <h3 className="sidebar-title">Recent Posts</h3>

          <div className="mt-3">
            {recentPosts.map(({ frontmatter }, index) => (
              <div className={`d-flex post-item mt-3`} key={index}>
                <img
                  src={getSrc(
                    frontmatter.image.childImageSharp.gatsbyImageData
                  )}
                  alt={""}
                  className="img-fluid"
                />
                <div className="ml-3">
                  <h4>
                    <a href={frontmatter.slug}>
                      {frontmatter.title.substring(0, 50)}
                    </a>
                  </h4>
                  <time dateTime={frontmatter.date}>{frontmatter.date}</time>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
