import { getSrc } from "gatsby-plugin-image"
import React from "react"
import { SideBar } from "./sidebar"

interface pressitemProps {
  data: any
}

export const PressItem: React.FC<pressitemProps> = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data
  const { frontmatter } = markdownRemark
  const { nodes } = allMarkdownRemark
  return (
    <section id="blog" className="blog">
      <div className="container" data-aos="fade-up">
        <div className="row g-5">
          <div className="col-lg-8 mb-5">
            <article className="blog-details">
              <div className="post-img">
                <video
                  controls
                  poster={getSrc(
                    frontmatter.image.childImageSharp.gatsbyImageData
                  )}
                >
                  <source src={frontmatter.video.publicURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <h2 className="title">{frontmatter.title}</h2>

              <div className="meta-top">
                <ul>
                  <li className="d-flex align-items-center">
                    <i className="bx bx-user"></i>{" "}
                    <a href="/press">{frontmatter.author}</a>
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="bx bx-time"></i>{" "}
                    <a href="/press">
                      <time dateTime={frontmatter.date}>
                        {frontmatter.date}
                      </time>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="content">
                <p>{frontmatter.shortDescription}</p>
              </div>
            </article>

            <div className="post-author d-flex align-items-center">
              <img
                src={getSrc(
                  frontmatter.authorImage.childImageSharp.gatsbyImageData
                )}
                className="rounded-circle flex-shrink-0"
                alt={frontmatter.author}
              />
              <div>
                <h4>{frontmatter.author}</h4>
                <div className="social-links">
                  <a href="https://twitter.com/BertPretorius">
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a href="https://www.facebook.com/bertpretorius/">
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/bertpretorius">
                    <i className="bx bxl-instagram"></i>
                  </a>
                </div>
                <p>
                  Pastor Bert Pretorius, with his wife Charné are the founding
                  pastors of 3C Church – a dynamic, multi-racial, multi-site,
                  vibrant and fast-growing church with over 30,000 members in
                  the City of Tshwane, South Africa.
                </p>
              </div>
            </div>
          </div>
          <SideBar recentPosts={nodes} />
        </div>
      </div>
    </section>
  )
}
