import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { PressItem } from "../components/Press/pressitem"
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb"
import Seo from "../components/seo"

const links = [
  { displayName: "Home", link: "/" },
  { displayName: "About", link: "/#about" },
  { displayName: "Contact", link: "/#contact" },
  { displayName: "Press", link: "/press" },
]

export default function Template({ data }) {
  const { markdownRemark, allMarkdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout navigationLinks={links}>
      <Seo title={frontmatter.title} />
      <main
        className={"mt-5"}
        id={"main"}
        style={{
          background: "url('../images/hero-bg.jpg') top center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Breadcrumb
          pageNames={[
            { name: "Press", slug: "/press" },
            { name: frontmatter.path },
          ]}
          title={frontmatter.title}
        />
        <PressItem data={data} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        shortDescription
        slug
        title
        author
        path
        authorImage {
            childImageSharp {
              gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
            }
          }
        video {
          publicURL
        }
        image {
            childImageSharp {
              gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
            }
          }
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          date
          shortDescription
          slug
          title
          image {
            childImageSharp {
              gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
