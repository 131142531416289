import React from "react"
import "./breadcrumb.css"

interface BreadcrumbProps {
  pageNames: Array<{ name: string; slug?: string }>
  title: String
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageNames, title }) => {
  return (
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{title}</h2>
          <ol>
            <li>
              <a href="/">Home</a>
            </li>
            {pageNames.map((pageName, index) =>
              pageName.slug ? (
                <li key={index}>
                  <a href={pageName.slug}>{pageName.name}</a>
                </li>
              ) : (
                <li key={index}>{pageName.name}</li>
              )
            )}
          </ol>
        </div>
      </div>
    </section>
  )
}
